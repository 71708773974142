import React, { useState, useEffect } from "react";
// import ScrollingNotification from '../home/ScrollingNotification';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import useHandleLinkClick from "../../useHandleLinkClick";
import UserInfo from "../../pages/register/UserInfo";

const BannerTwo = () => {
  const handleLinkClick = useHandleLinkClick();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    // Function to check screen size and update the state
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Call the function initially to set the correct value
    handleResize();

    return () => {
      // Cleanup: Remove the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="banner">
      <div className="banner-image">
        {/* <ScrollingNotification /> */}
        <div
          className="banner-carousel owl-theme owl-carousel"
          style={{ overflow: "hidden" }}
        >
          <div
            className="slide-item"
            style={{
              backgroundImage: "url(assets/images/banner/banner-1.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="banner-slide">
                    <div className="banner-content">
                      <div className="banner-content-wrapper">
                        <div className="banner-content-wrapper-inner">
                          <h4>Nurturing Minds</h4>
                          <h2>Find Your Calm</h2>
                          <p>Support is Here for You</p>
                          <div className="btn-group">
                            <div className="header-link-btn">
                              {/* <ScrollLink
                                to="userinfo-section"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-70}
                                onClick={handleLinkClick("userinfo-section")}
                                className="btn-1 btn-alt"
                              > 
                                Register <span />
                              </ScrollLink> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  {/* Render UserInfo only on desktop (screen width >= 992px) */}
                  {isDesktop && <UserInfo />}
                  {/* <div className="banner-right-content">
                    <div className="banner-right-image"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTwo;
