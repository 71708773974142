import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import "../App.css";
import { Modal, Button } from "react-bootstrap";
import useHandleLinkClick from "../useHandleLinkClick";
import FaqPreview from "./FaqPreview";
import RefPreview from "./RefPreview";
import { TermsPreview } from "../pages/register/TermsPreview.js";
import { PrivacyPolicyPreview } from "../pages/register/PrivacyPolicyPreview.js";
import SubscribePreview from "./SubscribePreview.js";
import "./Modal.css";

const Footer = () => {
  const handleLinkClick = useHandleLinkClick();
  const [showfaq, setShowfaq] = useState(false);
  const [showref, setShowref] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [showsubscribe, setShowsubsribe] = useState(false);

  const subscribeHandler = (e) => {
    setShowsubsribe(true);
  };

  const faqHandler = (e) => {
    setShowfaq(true);
  };
  const refHandler = (e) => {
    setShowref(true);
  };
  const docHandler = () => {
    setImageUrl(true);
  };
  const termsHandler = () => {
    setShowterms(true);
  };
  const privacyHandler = () => {
    setPrivacyPolicy(true);
  };
  const handleClose = () => {
    setShowfaq(false);
    setShowref(false);
    setImageUrl(false);
    setShowterms(false);
    setPrivacyPolicy(false);
    setShowsubsribe(false);
  };

  return (
    <footer
      className="main__footer home-two-footer p_relative"
      id="footer-section"
    >
      <div
        className="main__footer__top"
        style={{ backgroundImage: "url(assets/images/shape/shape-01.png)" }}
        hidden
      >
        <div className="footer__middle  p_relative d_block">
          <div className="auto_container">
            <div className="footer__middle__content">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 footer_column">
                  <div className="footer_widget left">
                    <div className="footer__logo">
                      <figure>
                        <img src="assets/images/logo.jpg" alt="" />
                      </figure>
                    </div>
                    <div className="widget_content p-justify">
                      <p>
                        <strong>GARIKE</strong> is a development organisation
                        engaged in building a New technical society in India
                        through its grass root to policy level action in
                        Technical Education, Health & Sanitisation, Community
                        development, Ecofriendly activities women & child
                        empowerment sectors.
                      </p>
                    </div>
                    {/* <div className="widget_media">
                      <ul>
                        <li>
                          <a href="#0">
                            <i className="flaticon-facebook-app-symbol"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <i className="flaticon-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <i className="flaticon-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <i className="flaticon-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 footer_column">
                  <div className="footer_widget links_widget ml_100">
                    <div className="widget_title">
                      <h4>Quick Links</h4>
                    </div>
                    <div className="widget_content" style={{ display: "flex" }}>
                      <ul className="links_list clearfix">
                        <li>
                          <ScrollLink
                            to="about-section"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onClick={handleLinkClick("about-section")}
                            style={{ cursor: "pointer" }}
                          >
                            About
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to="rewards-section"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onClick={handleLinkClick("rewards-section")}
                            style={{ cursor: "pointer" }}
                          >
                            Awards
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to="eligibility-section"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onClick={handleLinkClick("eligibility-section")}
                            style={{ cursor: "pointer" }}
                          >
                            Eligibility
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to="events-section"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onClick={handleLinkClick("events-section")}
                            style={{ cursor: "pointer" }}
                          >
                            Patron
                          </ScrollLink>
                        </li>
                        <li>
                          <span
                            className="modal-btn"
                            onClick={termsHandler}
                            style={{ cursor: "pointer" }}
                          >
                            Terms & Conditions
                          </span>
                        </li>
                        <li>
                          <span
                            className="modal-btn"
                            onClick={subscribeHandler}
                            style={{ cursor: "pointer" }}
                          >
                            Follow us
                          </span>
                        </li>
                      </ul>
                      <ul className="links_list clearfix pl-40">
                        <li>
                          <ScrollLink
                            to="gallery-section"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onClick={handleLinkClick("gallery-section")}
                            style={{ cursor: "pointer" }}
                          >
                            Gallery
                          </ScrollLink>
                        </li>
                        <li>
                          <span
                            className="modal-btn"
                            onClick={refHandler}
                            style={{ cursor: "pointer" }}
                          >
                            Refer a school
                          </span>
                        </li>
                        <li>
                          <span
                            className="modal-btn"
                            onClick={faqHandler}
                            style={{ cursor: "pointer" }}
                          >
                            FAQ
                          </span>
                        </li>
                        <li>
                          {/* <span className="modal-btn" onClick={docHandler} style={{ cursor: "pointer" }}>
                            View Legal Document
                          </span> */}
                          <a
                            href="https://drive.google.com/file/d/1txcv4nIRKm0P4Jyasrg6Fm_2D2zUBVqh/view?usp=sharing"
                            target="_blank"
                          >
                            View Legal Document
                          </a>
                        </li>
                        <li>
                          <span
                            className="modal-btn"
                            onClick={privacyHandler}
                            style={{ cursor: "pointer" }}
                          >
                            Privacy Policy
                          </span>
                        </li>
                        <li>
                          <a
                            href="https://exams.myadhyayan.in/"
                            target="_blank"
                          >
                            {" "}
                            Admin Login
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="widget_content mt-30">
                      <ul className="links_list clearfix">
                        <li>
                          <ScrollLink
                            to="userinfo-section"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onClick={handleLinkClick("userinfo-section")}
                            className="btn-1 btn-alt text-white"
                          >
                            Register
                          </ScrollLink>
                        </li>
                      </ul>
                      <Modal show={showfaq} size="xl" onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Frequently Asked Questions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <FaqPreview />
                        </Modal.Body>
                      </Modal>
                      <Modal show={showref} size="lg" onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Refer a school</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <RefPreview />
                        </Modal.Body>
                      </Modal>
                      <Modal show={imageUrl} size="md" onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Legal Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "80vh" }}>
                          <iframe
                            src="https://drive.google.com/file/d/1oalKe9_3-Nt-UZ2yGobLIfDlAsPld7O1/preview"
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "none",
                            }}
                            allow="autoplay"
                          ></iframe>
                        </Modal.Body>
                      </Modal>
                      <Modal show={showterms} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Terms & Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <TermsPreview />
                          {/* <FormDataPreview formData={formData} /> */}
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="button-group">
                            <Button
                              variant="danger btn-sm ml-5"
                              onClick={handleClose}
                            >
                              I Agree
                            </Button>
                          </div>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        show={privacyPolicy}
                        size="xl"
                        onHide={handleClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="text-center">
                            Privacy Policy
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <PrivacyPolicyPreview />
                        </Modal.Body>
                      </Modal>
                      <Modal show={showsubscribe} onHide={handleClose}>
                        <Modal.Header
                          closeButton
                          style={{ backgroundColor: "#00715D" }}
                        >
                          <Modal.Title>Follow Us</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <SubscribePreview />
                          {/* <FormDataPreview formData={formData} /> */}
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 footer_column">
                  <div className="footer_widget links_widget">
                    <div className="widget_title">
                      <h4>Contact</h4>
                    </div>
                    <div className="widget_content">
                      <ul className="links_list right">
                        {/* <li>
                          <a href="#">Garike trust (R)</a>
                        </li>
                        <li>
                          <a href="#">2nd Cross Rd, near siyarams building,</a>
                        </li>
                        <li>
                          <a href="#">Gandhinagar, Mandya,</a>
                        </li>
                        <li>
                          <a href="#">Karnataka 571401</a>
                        </li> */}
                        <li>Garike trust (R)</li>
                        <li>#11/189, Anchekeri bidi,</li>
                        <li>Srirangapattana,</li>
                        <li>Srirangapattana Taluk,</li>
                        <li>Mandya - 571438,</li>
                        <li>Karnataka state, India,</li>
                        <li>
                          <i className="flaticon-phone-call" /> +91 8310632618
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom p_relative">
        <div className="auto_container">
          <div className="bottom_inner  p_relative">
            <div className="copyright">
              <p>
                <a
                  href="https://vibhaa.in/"
                  target="_blank"
                  style={{ fontFamily: "inherit" }}
                >
                  Vibhaa Technologies Pvt Ltd
                </a>
                &copy; {new Date().getFullYear()}. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
