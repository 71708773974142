// FormDataPreview.js
import React, { useMemo } from "react";
import "./FormDataPreview.css";

const FormDataPreview = ({ formData }) => {
  const {
    states,
    state: selectedStateKey,
    districts,
    district: selectedDistrictKey,
    taluks,
    taluk: selectedTalukKey,
    events,
    event: selectedEventKey,
    payment_modes,
    payment_mode: selectedPaymentModeKey,
  } = formData;

  const selectedState = useMemo(
    () =>
      states && selectedStateKey
        ? states.find((state) => state.id == selectedStateKey)
        : null,
    [states, selectedStateKey]
  );

  const selectedDistrict = useMemo(
    () =>
      districts && selectedDistrictKey
        ? districts.find((district) => district.id == selectedDistrictKey)
        : null,
    [districts, selectedDistrictKey]
  );

  const selectedTaluk = useMemo(
    () =>
      taluks && selectedTalukKey
        ? taluks.find((taluk) => taluk.id == selectedTalukKey)
        : null,
    [taluks, selectedTalukKey]
  );

  const selectedEvent = useMemo(
    () =>
      events && selectedEventKey
        ? events.find((event) => event.id == selectedEventKey)
        : null,
    [events, selectedEventKey]
  );

  const selectedPaymentMode = useMemo(
    () =>
      payment_modes && selectedPaymentModeKey
        ? payment_modes.find((payment_mode) => payment_mode.id == selectedPaymentModeKey)
        : null,
    [payment_modes, selectedPaymentModeKey]
  );
  
  const formatKey = (key) => {
    if (key === "study") {
      return "Other Study";
    }
    if (key === "schoolName") {
      return "Institution Name";
    }
    if (key === "schoolPlace") {
      return "Institution Place";
    }
    if (key === "schoolContact") {
      return "Institution Contact";
    }
    if (key === "schoolCode") {
      return "Institution Code";
    }
    // Separate camel-cased words and capitalize each word
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel-cased words
      .split(" ") // Split the key into individual words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words back together
  };

  const displayValue = (key, value) => {
    if (key === "state") {
      return selectedState ? selectedState.state_title : " - ";
    } else if (key === "district") {
      return selectedDistrict ? selectedDistrict.district_title : " - ";
    } else if (key === "taluk") {
      return selectedTaluk ? selectedTaluk.name : " - ";
    } else if (key === "event") {
      return selectedEvent ? selectedEvent.title : " - ";
    } else if (key === "payment_mode") {
      return selectedPaymentMode ? selectedPaymentMode.modes : " - ";
    } else if (Array.isArray(value)) {
      return "Array";
    } else if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value;
  };

  // Filter out the "states", "districts", "taluks" and "termsAccepted" fields
  const filteredFormData = useMemo(
    () =>
      Object.entries(formData).filter(
        ([key]) =>
          key !== "states" &&
          key !== "districts" &&
          key !== "taluks" &&
          key !== "events" &&
          key !== "payment_modes" &&
          key !== "termsAccepted" &&
          key !== "donationLetter"
      ),
    [formData]
  );

  return (
    <div className="form-data-preview">
      <table className="table">
        <tbody>
          {filteredFormData.map(
            ([key, value], index) =>
              value && (
                <tr
                  key={key}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td>
                    <strong>{formatKey(key)}:</strong>
                  </td>
                  <td>{displayValue(key, value)}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(FormDataPreview);
