import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Preloader from "../../components/Preloader";
import Header from "../../components/Header";
import MobileMenu from "../../components/MobileMenu";
import BannerTwo from "../../components/home/BannerTwo";
import Vision from "../../components/home/Vision";
import AboutTwo from "../../components/home/AboutTwo";
import Event from "../../components/home/Event";
import Footer from "../../components/Footer";
import Scroller from "../../components/Scroller";
import UserInfo from "../register/UserInfo";
import Swal from "sweetalert2";

const Home = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    // Function to check screen size and update the state
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    // Add event listener on window resize
    window.addEventListener("resize", handleResize);

    // Call the function initially to set the correct value
    handleResize();

    return () => {
      // Cleanup: Remove the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Disable Keyboard Shortcuts
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey && (event.key === "c" || event.key === "p")) ||
        event.key === "PrintScreen"
      ) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    // Disable Right-Click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextMenu);

    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get("message");
    if (message) {
      const text =
        message === "SUCCESS"
          ? `<span className="success-message">
                🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
              </span>`
          : message === "CANCEL"
          ? `<span className="error-message">
              ❌ Payment has been cancelled.
            </span>`
          : message === "ERROR"
          ? `<span className="error-message">
                ❌ Something went wrong. Please contact us for further information.
              </span>`
          : `<span className="error-message">
                ❌ Oops! Failed. Please try again.
              </span>`;

      Swal.fire({
        title: "<strong>" + message + "</strong>",
        icon: message === "SUCCESS" ? "success" : "error",
        html: `${text}`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: `
          CLOSE
        `,
        // confirmButtonAriaLabel: "Thumbs up, great!",
        // cancelButtonText: `
        //   <i class="fa fa-thumbs-down"></i>
        // `,
        // cancelButtonAriaLabel: "Thumbs down",
      }).then(() => {
        // Redirect to the register page
        window.location.replace("/");
      });
    }

    const script = document.createElement("script");
    script.src = "assets/js/script.js";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [location]);

  return (
    <>
      {/* <Preloader /> */}
      <Header />
      <MobileMenu />
      <BannerTwo />
      {isMobile && <UserInfo />}{" "}
      {/* Render UserInfo only on screens below 992px */}
      <Vision />
      {/* <AboutTwo /> */}
      {/* <Event /> */}
      <Footer />
      <Scroller />
    </>
  );
};

export default Home;
