import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";
import useHandleLinkClick from '../useHandleLinkClick';
const Header = () => {
  const handleLinkClick = useHandleLinkClick();

  return (
    <header id="home-section" className="main-header style-one home-three-header">
      {/* header-top */}
      <div className="header-lower">
        <div className="header_top">
          <div className="auto_container">
            <div className="header_top_left">
              <ul className="top_left">
                <li>
                  <a href="#">
                    <i className="flaticon-email" /> events.suprabhawellness.in
                  </a>
                </li>
                {/* <li style={{ textAlign : "center"}}>
                  <img src="assets/images/icons/location.png" alt="icon" />
                  Garike trust (R), #11/189, Anchekeri bidi, Srirangapattana, 
                  Srirangapattana Taluk, Mandya - 571438, Karnataka state, India
                </li> */}
              </ul>
            </div>
            <div className="header_top_right">
              <div className="header_right_info">
                <ul className="top_right">
                  <li>
                    <a href="https://www.youtube.com/@suprabha.wellness"  target="_blank">
                      <i className="fa fa-youtube fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header_bottom p_relative">
          <div className="auto_container">
            <div className="outer-box">
              <div className="logo-box">
                <figure className="logo">
                  <Link to="/">
                    <img src="assets/images/logo.jpg" alt="Suprabha Wellness" style={{ width : "225px"}}/>
                  </Link>
                </figure>
              </div>
              <div className="menu-area">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler">
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <Nav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
