import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import useHandleLinkClick from '../useHandleLinkClick';

const Nav = () => {
  const handleLinkClick = useHandleLinkClick();  

  return (
    <>
      <nav id="header" className="main-menu navbar-expand-md navbar-light p-10">
        <div
          className="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            <li>
              <ScrollLink
                to="home-section"
                smooth={true}
                duration={500}
                offset={-70}
                onClick={handleLinkClick("home-section")}
                >
                Home
              </ScrollLink>
            </li>
            <li>
                <ScrollLink
                  to="banner"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("banner")}
                >
                  Register
                </ScrollLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Nav;
