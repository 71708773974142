import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import useHandleLinkClick from '../useHandleLinkClick';

const MobileMenu = () => {
  const handleLinkClick = useHandleLinkClick();  

  return (
    <div className="mobile-menu">
      <div className="menu-backdrop" />
      <div className="close-btn">
        <i className="fas fa-times" />
      </div>
      <nav className="menu-box">
        <div className="nav-logo">
          <a href="#">
            <img src="assets/images/logo.jpg" alt="Suprabha Wellness" />
          </a>
        </div>
        <div className="menu-outer">
          <div
            className="collapse navbar-collapse show clearfix"
            id="navbarSupportedContent"
          >
            <ul className="navigation clearfix">
             <li>
                <ScrollLink
                  to="home-section"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("home-section")}
                  >
                  Home
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="userinfo-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                  onClick={handleLinkClick("userinfo-section")}
                >
                  Register
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-info" hidden>
          <h4>Contact Info</h4>
          <ul>
            {/* <li>
              <a href="#">Garike trust (R)</a>
            </li>
            <li>
              <a href="#">2nd Cross Rd, near siyarams building,</a>
            </li>
            <li>
              <a href="#">Gandhinagar, Mandya,</a>
            </li>
            <li>
              <a href="#">Karnataka 571401</a>
            </li> */}
            <li>
              Garike trust (R)
            </li>
            <li>
              #11/189, Anchekeri bidi,
            </li>
            <li>
              Srirangapattana,
            </li>
            <li>
              Srirangapattana Taluk, 
            </li>
            <li>
              Mandya - 571438,
            </li>
            <li>
              Karnataka state, India,
            </li>
            <li>
              <i className="flaticon-phone-call" /> +91 8310632618
            </li>
          </ul>
        </div>
        <div className="social-links" hidden>
          <ul className="clearfix">
            <li>
              <a href="https://www.youtube.com/@suprabha.wellness"  target="_blank">
                <span className="fa fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;
