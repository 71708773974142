//Calculate the remaining workshop capacity (slots)
// UserInfo.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import "jquery-validation";
import Swal from "sweetalert2";

import FormField from "../../components/register/FormField";
import SelectOption from "../../components/register/SelectOption";
import {
  educationLevelOptions,
  branchOptions,
  yearOptions,
  genderOptions,
} from "./educationOptions.js";
import { TermsPreview } from "./TermsPreview.js";
import { PrivacyPolicyPreview } from "./PrivacyPolicyPreview.js";
import FormDataPreview from "./FormDataPreview";
import "./Form.css";
import "./Modal.css";
import PaymentModal from "../../pages/payment/PaymentModal";
import Preloader from "../../components/Preloader";

const UserInfo = () => {
  const [formData, setFormData] = useState({
    name: "",
    // gender: "",
    // state: "",
    // district: "",
    // taluk: "",
    // states: [],
    // districts: [],
    // taluks: [],
    // // pin: "",
    // place: "",
    phoneNumber: "",
    email: "",
    // event: "",
    // payment_mode: "",
    // events: [],
    // payment_modes: [],
    // schoolName: "",
    // schoolPlace: "",
    // schoolContact: "",
    // schoolCode: "",
    // termsAccepted: false,
    // educationLevel: "",
    // branch: "",
    // year: "",
    // study:"",
    // specialization: "",
  });
  const captchaSitekey = "6LfEcHQpAAAAANtrim9TrTzmuUkl8f44CStS_1E2"; // works for the localhost, vibhaait.in and myadhyayan.in
  const [showPreview, setShowPreview] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  // const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const location = useLocation();
  const baseURL = 'https://events-admin.vibhaait.in/public/api';
  // const baseURL = "http://127.0.0.1:8000/api";
  useEffect(() => {
    // Fetch the events
    axios
      .get(`${baseURL}/events-details`)
      .then((response) => {
        setFormData((prevState) => ({
          ...prevState,
          events: response.data.workshops,
          payment_modes: response.data.payment_mode,
        }));
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });

    // Fetch the states
    axios
      .get(`${baseURL}/states`)
      .then((response) => {
        setFormData((prevState) => ({
          ...prevState,
          states: response.data,
        }));
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });

    const searchParams = new URLSearchParams(location.search);
    const message = searchParams.get("message");
    if (message) {
      const text =
        message === "SUCCESS"
          ? `<span className="success-message">
                🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
              </span>`
          : message === "ERROR"
          ? `<span className="error-message">
                ❌ Something went wrong. Please contact us for further information.
              </span>`
          : `<span className="error-message">
                ❌ Oops! Payment Failed. Please try again.
              </span>`;

      Swal.fire({
        title: "<strong>" + message + "</strong>",
        icon: message === "SUCCESS" ? "success" : "error",
        html: `${text}`,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: `
          <i class="fa fa-thumbs-up"></i> Great!
        `,
        confirmButtonAriaLabel: "Thumbs up, great!",
        // cancelButtonText: `
        //   <i class="fa fa-thumbs-down"></i>
        // `,
        // cancelButtonAriaLabel: "Thumbs down",
      }).then(() => {
        // Redirect to the register page
        // window.location.replace("/");
      });
    }

    $("#userInfoForm").validate({
      rules: {
        name: "required",
        // pin: {
        //   required: true,
        //   minlength: 6,
        //   maxlength: 6,
        //   digits: true,
        // },
        // place: "required",
        phoneNumber: {
          required: true,
          minlength: 10,
          maxlength: 10,
          digits: true,
        },
        // state: {
        //   required: true,
        // },
        // district: {
        //   required: true,
        // },
        // taluk: {
        //   required: true,
        // },
        // gender: {
        //   required: true,
        // },
        // event: {
        //   required: true,
        // },
        // payment_mode: {
        //   required: true,
        // },
      },

      messages: {
        name: "Please enter your name",
        // pin: {
        //   required: "* Please enter your PIN",
        //   minlength: $.validator.format("* PIN must be {0} digits long"),
        //   maxlength: $.validator.format("* PIN must be {0} digits long"),
        //   digits: "* Please enter only digits",
        // },
        // place: "Please enter your place",
        phoneNumber: {
          required: "* Please enter your phone number",
          minlength: $.validator.format(
            "* Phone number must be {0} digits long"
          ),
          maxlength: $.validator.format(
            "* Phone number must be {0} digits long"
          ),
          digits: "* Please enter only digits",
        },
        // state: "Please select the state",
        // district: "Please select the district",
        // taluk: "Please select the taluk",
        // gender: "Please select the gender",
        // event: "Please select the event",
        // payment_mode: {
        //   required: "* Please select the payment mode",
        // },
      },
      errorPlacement: function (error, element) {
        if (element.attr("type") === "checkbox") {
          error.insertAfter(element.closest(".terms-checkbox"));
        } else {
          error.insertAfter(element);
        }
      },
      submitHandler: function (form, event) {
        event.preventDefault();
        // Your form submission logic here
        // For example, call handleSubmit() function
        setShowPreview(true);
      },
    });
  }, []);

  const fetchDistricts = async (stateId) => {
    try {
      if (!stateId) {
        // If stateId is empty, clear the districts dropdown
        setFormData((prevData) => ({
          ...prevData,
          districts: [],
          taluks: [], // Clear the taluks dropdown
        }));
        return; // Return early, no need to make the API call
      }

      const response = await axios.get(
        `${baseURL}/states/${stateId}/districts`
      );
      setFormData((prevData) => ({
        ...prevData,
        districts: response.data,
      }));
    } catch (error) {
      setFormData((prevData) => ({
        ...prevData,
        districts: [],
        taluks: [], // Clear the taluks dropdown
      }));
      console.error("Error fetching districts:", error);
    }
  };

  const fetchTaluks = async (districtId) => {
    try {
      if (!districtId) {
        // If districtId is empty, clear the taluks dropdown
        setFormData((prevData) => ({
          ...prevData,
          taluks: [],
        }));
        return; // Return early, no need to make the API call
      }

      const response = await axios.get(
        `${baseURL}/districts/${districtId}/taluks`
      );
      setFormData((prevData) => ({
        ...prevData,
        taluks: response.data,
      }));
    } catch (error) {
      setFormData((prevData) => ({
        ...prevData,
        taluks: [],
      }));
      console.error("Error fetching taluks:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      // If the changed field is educationLevel, reset the related fields
      if (name === "educationLevel") {
        return {
          ...prevData,
          [name]: value,
          branch: "",
          year: "",
          study: "",
          specialization: "",
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
    if (name === "state") {
      fetchDistricts(value);
      fetchTaluks(value);
    } else if (name === "district") {
      fetchTaluks(value);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPreview(true);
    // Handle form submission logic here
    // console.log(formData);
  };

  const termsHandler = (e) => {
    setShowterms(true);
  };
  const privacyHandler = () => {
    setPrivacyPolicy(true);
  };

  const handleClose = () => {
    setShowPreview(false);
    setShowterms(false);
    setCaptchaVerified(false);
    setPrivacyPolicy(false);
  };

  const handleCaptchaChange = (value) => {
    // Check if the ReCAPTCHA has been successfully completed
    setCaptchaVerified(value !== null);
  };
  const handlePayNow = () => {
    // if (captchaVerified) {
    // Perform "Register Now" action if ReCAPTCHA is successfully completed
    // setIsPaymentModalOpen(true);

    console.log("action triggered");
    // setIsLoading(true);
    // performPaymentAction();
    // Swal.fire({
    //   title: "<strong>NOTE!!!</strong>",
    //   icon: "info",
    //   html: "If you stop the payment process midway, you'll need to wait two hours and then re-register with the same details. Thank you for your patience!",
    //   showCloseButton: false,
    //   showCancelButton: false,
    //   focusConfirm: false,
    //   confirmButtonText: "Continue"
    // }).then(() => {
    //   setIsLoading(true);
    //   // performPaymentAction();         // Comment this after enabling the razorpay
    //   performPaymentActionLive();  // Uncomment this after enabling the razorpay
    // });
    setShowPreview(false);
    setIsLoading(true);
    performPaymentActionLive();
    // } else {
    //   alert("Please complete the ReCAPTCHA before proceeding.");
    // }
  };

  const performPaymentAction = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/payment/user-info`,
        formData
      );
      // Redirect user to paymentUrl or display QR code for payment
      // const paymentUrl = response.data.redirect;
      // window.location.href = paymentUrl;
      // window.location.reload();
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      if (response.data.success) {
        console.log(response.data.success);
        const resMessage = "SUCCESS";
        const text =
          resMessage === "SUCCESS"
            ? `<span className="success-message">
                  🎉 Congratulations! You have successfully registered! <br/>Please check your email for more information.
                </span>`
            : resMessage === "ERROR"
            ? `<span className="error-message">
                  ❌ Something went wrong. Please contact us for further information.
                </span>`
            : `<span className="error-message">
                  ❌ Oops! Payment Failed. Please try again.
                </span>`;

        Swal.fire({
          title: "<strong>" + resMessage + "</strong>",
          icon: resMessage === "SUCCESS" ? "success" : "error",
          html: `${text}`,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: `
            <i class="fa fa-thumbs-up"></i> Great!
          `,
          confirmButtonAriaLabel: "Thumbs up, great!",
          // cancelButtonText: `
          //   <i class="fa fa-thumbs-down"></i>
          // `,
          // cancelButtonAriaLabel: "Thumbs down",
        }).then(() => {
          // Redirect to the register page
          window.location.replace("/");
        });
      }
    } catch (error) {
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      const text =
        '<span className="error-message">❌ Please try again.</span>';

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        let errorHtml = "<ul >";
        let index = 1;
        for (let fieldName in errors) {
          errorHtml += `<li style="text-align: left;">${index}. ${errors[fieldName][0]}</li>`;
          index++;
        }
        errorHtml += "</ul>";
        Swal.fire({
          title: "<strong>Error initiating payment</strong>",
          icon: "error",
          html: errorHtml,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      } else {
        Swal.fire({
          title: "<strong>Unexpected Error</strong>",
          icon: "error",
          html: "An unexpected error occurred. Please try again later.",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      }
    }
  };

  const performPaymentActionLive = async () => {
    try {
      // Create a shallow copy of formData
      let dataToSend = { ...formData };

      // Delete the keys you don't want to send
      delete dataToSend.states;
      delete dataToSend.districts;
      delete dataToSend.taluks;
      delete dataToSend.events;
      delete dataToSend.payment_modes;

      // Now send the modified data
      const response = await axios.post(
        `${baseURL}/payment/initiate`,
        dataToSend
      );

      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);

      if (response.data.success) {
        if (response.data.discountedAmount) {
          // QR code with discount amount
          const text = `
            <div class="success-message" style="line-height:2rem!important;">
              🎉 Congratulations on taking a step to heal and elevate!  
            </div>

            <div class="success-message" style="line-height:2rem!important;">
              <strong>Your seat has been reserved.</strong>
            </div>

            <div class="success-message" style="line-height:2rem!important;">
              To confirm your participation, please complete the energy exchange for the workshop.
            </div>

            <div style="line-height:2rem!important;">
              <strong>Expected Energy Exchange</strong>: ₹${response.data.actualAmount}
            </div>

            <div style="line-height:2rem!important;">
              <strong>Early Bird Discount</strong>: ${response.data.discount}
            </div>

            <div style="line-height:2rem!important;">
              <strong>Actual Energy Exchange</strong>: ₹${response.data.discountedAmount}
            </div>

            <div style="margin-top: 10px!important">
              <strong>QR Code</strong>:
              <div>
                <img src="assets/images/suprabha_wellness_qr_code.jpeg" alt="QR Code" style="width:225px!important;" />
              </div>
              <div style="margin-top: 10px!important;">
                <a href="assets/images/suprabha_wellness_qr_code.jpeg" download class="btn btn-primary" style="color:white!important;">Click here to download the QR code</a>
              </div>
            </div>

            <div style="margin-top: 10px!important; line-height:2rem!important;">
              <div>
                <strong>UPI ID</strong>: aadi.nd@axisbank
              </div>
              <div>
                <strong>UPI Number</strong>: +91-9611319643
              </div> 
            </div>

            <div style="line-height:2rem!important;">
              <strong>For Bank Transfers:</strong>
              <div>
                Name: Adarsh Narayan D
              </div>
              <div>
                Current Account: 924020000027886
              </div>
              <div>
                IFSC: UTIB0001002
              </div>
            </div>

            <div style="margin-top: 10px!important;">
              Once you make the energy exchange, share the screenshot via WhatsApp to 
              <a href="https://wa.me/919611319643" class="btn btn-primary" style="color:white!important;"  target="_blank">
                +91-9611319643
              </a>
            </div>

            <div style="margin-top: 15px!important;">
              May your investments come back to you multifold! Thank you!
            </div>`;

          Swal.fire({
            title: "<strong>CONFIRM YOUR SEAT</strong>",
            icon: "info",
            html: `${text}`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: `
              <i class="fa fa-thumbs-up"></i> Great!
            `,
            confirmButtonAriaLabel: "Thumbs up, great!",
          }).then(() => {
            // Reload the website.
            window.location.replace("/");
          });
        } else if (response.data.amount) {
          // QR code with full amount

          // const text = `<div class="success-message" style="line-height:2rem!important;">
          //               🎉 Registration complete! Please scan the QR code to pay <span style="color:#6800ff;font-weight:700">₹${response.data.amount}</span>, then send a screenshot of your payment receipt to the specified WhatsApp number <span style="color:#6800ff;font-weight:700">+91-9611319643</span> to book your seat!
          //               </div>
          //               <div style="margin-top: 5px; margin-bottom: 10px!important">
          //                 <a
          //                   href="${response.data.payment_url}"
          //                   style="color: white!important;"
          //                   class="btn btn-info px-2"
          //                   hidden
          //                 >
          //                   Pay Now
          //                 </a>
          //                 <a
          //                   href="assets/images/suprabha_wellness_qr_code.jpeg"
          //                   download
          //                   style="color: white!important;"
          //                   class="btn btn-primary"
          //                 >
          //                   Click here to download the QR code
          //                 </a>
          //               </div>
          //               <img
          //                 src="assets/images/suprabha_wellness_qr_code.jpeg"
          //                 alt="Suprabha Wellness QR Code"
          //                 style="width : 225px!important"
          //               />
          //               `;

          const text = `
            <div class="success-message" style="line-height:2rem!important;">
              🎉 Congratulations on taking a step to heal and elevate!  
            </div>

            <div class="success-message" style="line-height:2rem!important;">
              <strong>Your seat has been reserved.</strong>
            </div>

            <div class="success-message" style="line-height:2rem!important;">
              To confirm your participation, please complete the energy exchange for the workshop.
            </div>

            <div style="line-height:2rem!important;">
              <strong>Expected Energy Exchange</strong>: ₹${response.data.actualAmount}
            </div>

            <div style="line-height:2rem!important;">
              <strong>Early Bird Discount</strong>: ${response.data.discount}
            </div>

            <div style="line-height:2rem!important;">
              <strong>Actual Energy Exchange</strong>: ₹${response.data.amount}
            </div>

            <div style="margin-top: 10px!important">
              <strong>QR Code</strong>:
              <div>
                <img src="assets/images/suprabha_wellness_qr_code.jpeg" alt="QR Code" style="width:225px!important;" />
              </div>
              <div style="margin-top: 10px!important;">
                <a href="assets/images/suprabha_wellness_qr_code.jpeg" download class="btn btn-primary" style="color:white!important;">Click here to download the QR code</a>
              </div>
            </div>

            <div style="margin-top: 10px!important; line-height:2rem!important;">
              <div>
                <strong>UPI ID</strong>: aadi.nd@axisbank
              </div>
              <div>
                <strong>UPI Number</strong>: +91-9611319643
              </div> 
            </div>

            <div style="line-height:2rem!important;">
              <strong>For Bank Transfers:</strong>
              <div>
                Name: Adarsh Narayan D
              </div>
              <div>
                Current Account: 924020000027886
              </div>
              <div>
                IFSC: UTIB0001002
              </div>
            </div>

            <div style="margin-top: 10px!important;">
              Once you make the energy exchange, share the screenshot via WhatsApp to 
              <a href="https://wa.me/919611319643" class="btn btn-primary" style="color:white!important;"  target="_blank">
                +91-9611319643
              </a>
            </div>

            <div style="margin-top: 15px!important;">
              May your investments come back to you multifold! Thank you!
            </div>`;
          Swal.fire({
            title: "<strong>CONFIRM YOUR SEAT</strong>",
            icon: "info",
            html: `${text}`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: `
              <i class="fa fa-thumbs-up"></i> Great!
            `,
            confirmButtonAriaLabel: "Thumbs up, great!",
          }).then(() => {
            // Reload the website.
            window.location.replace("/");
          });
        } else if (response.data.title) {
          setIsLoading(false);
          Swal.fire({
            title: `<strong>${response.data.title}</strong>`,
            icon: "error",
            html: `${response.data.message}`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
          }).then(() => {
            // Reload the website.
            window.location.replace("/");
          });
        }
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "<strong>Error..!</strong>",
          icon: "error",
          html: "Failed to register. Please try again later.",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      }
    } catch (error) {
      setShowPreview(false);
      setShowterms(false);
      setPrivacyPolicy(false);
      setCaptchaVerified(false);
      setIsLoading(false);
      const text =
        '<span className="error-message">❌ Please try again.</span>';

      if (error.response && error.response.status === 422) {
        const errors = error.response.data.errors;
        let errorHtml = "<ul >";
        let index = 1;
        for (let fieldName in errors) {
          errorHtml += `<li style="text-align: left;">${index}. ${errors[fieldName][0]}</li>`;
          index++;
        }
        errorHtml += "</ul>";
        Swal.fire({
          title: "<strong>Error initiating the payment</strong>",
          icon: "error",
          html: errorHtml,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      } else {
        Swal.fire({
          title: "<strong>Unexpected Error</strong>",
          icon: "error",
          html: "An unexpected error occurred. Please try again later.",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
        });
      }
    }
  };

  return (
    <section className="user-info" id="userinfo-section">
      {/* <div className="pattern-layer"></div> */}
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="align-title">
              <h5 style={{ color: "#015BA4" }}>Register Now</h5>
            </div>
          </div>
          <div className="col-xl-12">
            {/* <form onSubmit={handleSubmit}> */}
            <form id="userInfoForm">
              <div className="row">
                <div className="col-md-12">
                  <FormField
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormField
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FormField
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              {/*
              <div className="row">
                <div className="col-md-6">
                  <FormField
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name (as in Aadhar card)"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <SelectOption
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    options={genderOptions.map((gender) => ({
                      id: gender,
                      title: gender,
                    }))}
                    placeholder="Select Gender"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectOption
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    options={formData.states.map((state) => ({
                      id: state.id,
                      title: state.state_title,
                    }))}
                    placeholder="Select State"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <SelectOption
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    options={formData.districts.map((district) => ({
                      id: district.id,
                      title: district.district_title,
                    }))}
                    placeholder="Select District"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectOption
                    name="taluk"
                    value={formData.taluk}
                    onChange={handleChange}
                    options={formData.taluks.map((taluk) => ({
                      id: taluk.id,
                      title: taluk.name,
                    }))}
                    placeholder="Select Taluk"
                    required
                  />
                </div>*/}
              {/* <div className="col-md-6">
                  <FormField
                    type="text"
                    name="pin"
                    value={formData.pin}
                    onChange={handleChange}
                    placeholder="PIN"
                    autoComplete="OFF"
                    required
                  />
                </div> */}
              {/* <div className="col-md-6">
                  <FormField
                    type="text"
                    name="place"
                    value={formData.place}
                    onChange={handleChange}
                    placeholder="Place"
                    autoComplete="OFF"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormField
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <FormField
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectOption
                    name="event"
                    value={formData.event}
                    onChange={handleChange}
                    options={formData.events.map((event) => ({
                      id: event.id,
                      title: event.title,
                    }))}
                    placeholder="Select Events"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <SelectOption
                    name="payment_mode"
                    value={formData.payment_mode}
                    onChange={handleChange}
                    options={formData.payment_modes.map((mode) => ({
                      id: mode.id,
                      title: mode.modes,
                    }))}
                    placeholder="Select Payment Mode"
                    required
                  />
                </div>
              </div>*/}
              <div className="button-container text-center">
                <button
                  type="submit"
                  className="btn-1 p-2"
                  style={{
                    fontFamily: "initial",
                    backgroundColor: "#015BA4",
                    borderRadius: 5,
                  }}
                  disabled={isLoading} // Disable the button when loading
                >
                  {isLoading ? "Submitting..." : "Preview"}
                  {/* Change the text when loading */}
                  <span />
                </button>
              </div>
            </form>
            <Modal show={showPreview} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormDataPreview formData={formData} />
              </Modal.Body>
              <Modal.Footer>
                {/* <ReCAPTCHA
                  sitekey={captchaSitekey}
                  onChange={handleCaptchaChange}
                  style={{ transform: "scale(0.8)" }} // Adjust the scale factor as needed
                /> */}

                <div className="button-group">
                  <Button
                    variant="primary btn-sm"
                    onClick={handlePayNow}
                    // disabled={!captchaVerified} // Disable button if ReCAPTCHA is not verified
                  >
                    Register Now
                  </Button>
                  <Button variant="danger btn-sm ml-5" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal show={showterms} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TermsPreview />
                {/* <FormDataPreview formData={formData} /> */}
              </Modal.Body>
              <Modal.Footer>
                <div className="button-group">
                  <Button variant="danger btn-sm ml-5" onClick={handleClose}>
                    I Agree
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal show={privacyPolicy} size="xl" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PrivacyPolicyPreview />
              </Modal.Body>
            </Modal>
            {/* <PaymentModal
              show={isPaymentModalOpen}
              onHide={() => setIsPaymentModalOpen(false)}
              onPayNow={performPaymentAction} // You can pass your payment action function here
            /> */}
            {isLoading && <Preloader />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserInfo;
