import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import "./Modal.css";

export const TermsPreview = () => {
  return (
    <ListGroup as="ol" numbered>
      <ListGroup.Item as="li" className="justify-text">
        Fill all necessary details on required fields.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Don't pay the enrollment fee through cash to anybody. If anybody asks, please inform us by calling or emailing. Still, if paid, Adhyayan scholarship promoters are NOT responsible.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Don't share any personal information like Aadhaar no, PAN no, and bank details etc., to anybody on any mode if asked in the name of Adhyayan scholarship. If shared and valuables are lost, Adhyayan scholarship promoters are NOT responsible.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Save the registration number received through email and SMS properly. If lost, apply for the recovery option through the website by logging in.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Only one attempt is allowed per student.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        After logging into the examination, if the internet connection is lost, battery power is lost, or the device is restarted, Adhyayan scholarship promoters are NOT responsible.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Examination date & time are announced in the beginning. If there are any changes, they will be announced through the website only. Enrolled students should regularly check the Adhyayan scholarship website because there is NO personal communication.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Once the examination is started by the student, they have 60 minutes only to complete the examination without any interruption.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        The scholarship prize amount is from 5K to 1 Lakh. It is a cash reward only through cheque/D.D.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        The scholarship amount is distributed based on the fastest completion of the examination with the highest to lowest marks obtained only.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        If anybody promises to get the prize amount by paying a commission, Adhyayan scholarship promoters are NOT responsible.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        Adhyayan scholarship programmers have full rights to change any terms and conditions without advance information on the website or personally.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        The scholarship subscription fee is valid for 2024-25 examination only.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
        <strong>No Refunds</strong> : Once payment has been made for the examination, the amount is non-refundable. Under no circumstances will refunds be issued, including but not limited to, changes in personal circumstances, errors in registration, or dissatisfaction with the exam process.
      </ListGroup.Item>
      <ListGroup.Item as="li" className="justify-text">
      <strong>No Cancellations</strong> : Once you have registered and paid for the examination, cancellation of registration is not permitted. You will not be able to cancel your registration or transfer your exam fees to another exam or candidate.
      </ListGroup.Item>
    </ListGroup>
  );
};

