import React, { useState, useEffect } from "react";
import axios from "axios";

const Vision = () => {
  const [showWorkshopDetails, setShowWorkshopDetails] = useState({
    title: "",
    description: "",
    image: "",
    loading: true, // Start with loading set to true
  });

  const baseURL = 'https://events-admin.vibhaait.in/public/api';
  // const baseURL = "http://127.0.0.1:8000/api";

  useEffect(() => {
    // Fetch the active event details
    axios
      .get(`${baseURL}/active-workshop`)
      .then((response) => {
        const {
          title,
          description,
          workshop_image,
        } = response.data.activeWorkshop;

        setShowWorkshopDetails({
          title: title || "No active workshop details available at the moment.",
          description:
            description || "Currently, there are no workshops to display.",
          image: workshop_image || "assets/images/default.png",
          loading: false, // Data has been successfully loaded
        });
      })
      .catch((error) => {
        console.error("Error fetching event details:", error);
        setShowWorkshopDetails({
          title: "Error loading workshop details.",
          description: "Unable to fetch workshop information.",
          image: "assets/images/error.jpg",
          loading: false, // Data load failed, but loading is finished
        });
      });
  }, []);

  // Function to split description into paragraphs
  const splitDescriptionIntoParagraphs = (description) => {
    // Split description into sentences
    const sentences = description.match(/[^.!?]+[.!?]+/g) || [];

    // Group sentences into paragraphs with at least 5 sentences each
    const paragraphs = [];
    for (let i = 0; i < sentences.length; i += 5) {
      paragraphs.push(sentences.slice(i, i + 5).join(" "));
    }

    return paragraphs;
  };

  // Get paragraphs from description
  const paragraphs = splitDescriptionIntoParagraphs(
    showWorkshopDetails.description
  );

  return (
    <section className="mission">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="common-title">
              <h4 style={{ textAlign: "center", textTransform: "capitalize" }}>
                {showWorkshopDetails.title}
              </h4>
            </div>
            <div className="mission-container">
              <div className="mission-shape">
                <img src="assets/images/shape/mission-shape.png" alt="shape" />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="col-md-12 mt-0">
                    <div
                      className="mission-contant wow fadeInDown animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      {showWorkshopDetails.loading ? (
                        <p>Loading...</p> // Show a loading indicator while data is being fetched
                      ) : (
                        <div className="mossion-info">
                          <h6>About this Workshop</h6>
                          {paragraphs.length > 0 ? (
                            paragraphs.map((para, index) => (
                              <p key={index} className="p-justify py-2">
                                {para}
                              </p>
                            ))
                          ) : (
                            <p className="p-justify py-3">
                              {showWorkshopDetails.description}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="col-md-12">
                    <div
                      className="event-3-left wow fadeInLeft animated"
                      style={{
                        visibility: "visible",
                        animationName: "fadeInLeft",
                      }}
                    >
                      <div className="event-3-left-image">
                        {showWorkshopDetails.loading ? (
                          <p>Loading image...</p> // Show a loading indicator while image is being fetched
                        ) : (
                          <img
                            src={showWorkshopDetails.image}
                            alt="workshop"
                          />
                        )}
                      </div>
                      <div className="mossion-info pt-3">
                        <p className="p-justify contact-details my-3">
                          <strong>
                            Contact us on WhatsApp for more details:
                            <a
                              href="https://wa.me/919611319643"
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label="Contact us on WhatsApp"
                              className="whatsapp-link"
                            >
                              <span className="fa fa-whatsapp fa-1x px-2" />
                            </a>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
